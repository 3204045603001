import { useSelector } from 'react-redux';
import FilterList from '@material-symbols/svg-400/outlined/filter_list.svg';
import Trash from '@material-symbols/svg-400/outlined/delete.svg';
import store, { RootState } from '@/redux/store';
import EnumDropdownMultiselect, { EnumDropdownStyle } from '../Enum/EnumDropdownMultiselect';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import getMultiselectNewState from '@/utils/getMultiselectNewState';
import { DatePicker, dateFormat } from '../ui/date-picker';

import Reset from '@material-symbols/svg-400/outlined/autorenew.svg';
import Close from '@material-symbols/svg-400/outlined/close.svg';

import {
	setFocusSelection as setFocusSelectionRedux,
	setTab as setTabRedux,
	setCompany as setCompanyRedux,
	setDrug as setDrugRedux,
	setDocumentType as setDocumentTypeRedux,
	setStartDate as setStartDateRedux,
	setEndDate as setEndDateRedux,
	setItems
} from '@/redux/focusSlice';

import { DropdownMenu, DropdownMenuContent, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';

import { Button } from '../ui/button';
import formatName from '@/utils/format/formatName';
import { format } from 'date-fns';
import NavIcon from './NavIcon';
import { setOverlay } from '@/redux/layoutSlice';
import AdaptivePopover from './AdaptivePopover';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import AdaptiveEnumDropdown from '../Enum/AdaptiveEnumDropdown';
import { cn } from '@/utils/lib/utils';
import { z_classes } from '@/utils/z';

const resetFocusFilters = () =>
{
	console.log('Resetting focus filters');
	store.dispatch(setTabRedux({ id: '', title: '' }));
	store.dispatch(setCompanyRedux([]));
	store.dispatch(setDrugRedux([]));
	store.dispatch(setDocumentTypeRedux([]));
	store.dispatch(setStartDateRedux('01/01/2010'));
	store.dispatch(setEndDateRedux(format(new Date(), dateFormat)));
	store.dispatch(setItems([]));
};

const FocusFilters = ({ includeTab }: { includeTab?: boolean }) =>
{
	const tab = useSelector((state: RootState) => state.focus.tab);
	const setTab = (value: { id: string; title: string }) => store.dispatch(setTabRedux(value));

	const company = useSelector((state: RootState) => state.focus.company);
	const setCompany = (value: string[]) =>
	{
		const realValues = value.filter(v => v !== 'Any Company' && v !== 'Select a company');
		console.log('Setting company', realValues);
		store.dispatch(setCompanyRedux(realValues));
	};

	const drug = useSelector((state: RootState) => state.focus.drug);
	const setDrug = (value: string[]) =>
	{
		const realValues = value.filter(v => v !== 'Any Drug' && v !== 'Select a drug');
		store.dispatch(setDrugRedux(realValues));
	};

	const documentType = useSelector((state: RootState) => state.focus.documentType);
	const setDocumentType = (value: string[]) =>
	{
		//Only set if it's different
		if (JSON.stringify(value) !== JSON.stringify(documentType))
		{
			store.dispatch(setDocumentTypeRedux(value));
		}
	};

	const startDate = useSelector((state: RootState) => state.focus.startDate);
	const setStartDate = (value: Date) => store.dispatch(setStartDateRedux(format(value, dateFormat)));

	const endDate = useSelector((state: RootState) => state.focus.endDate);
	const setEndDate = (value: Date) => store.dispatch(setEndDateRedux(format(value, dateFormat)));

	const sessionData = useSelector((state: RootState) => state.session.sessionData);

	//const documentTypeOptions = ["Any Document Type", "news_article", "press_release", "sec_filing", "sec_filing_contract", "company_presentation",	"journal_article", "patent", "fda_label", "company_web_page"]
	const documentTypeOptions = [
		//'Any Document Type',
		'earnings_call_transcript',
		'press_release',
		'sec_filing',
		'maven_analysis',
		//"trial",
		// 'company_presentation',
		'scientific_poster'
	];

	const [companyOptions, setCompanyOptions] = useState<string[]>([]);

	const enumsLoaded = useSelector((state: RootState) => state.enums.loaded);
	const enums = useSelector((state: RootState) => state.enums.enums);
	const drugs = useSelector((state: RootState) => state.enums.enums.drugs);
	const companies = useSelector((state: RootState) => state.enums.enums.companies);

	const firstRowColspanClasses = 'md:py-1 col-span-12 @lg:col-span-12 @xl:col-span-6 @7xl:col-span-3';
	const secondRowColspanClasses = 'md:py-1 col-span-12 @3xl:col-span-4 @7xl:col-span-2';
	const thirdRowColspanClasses = 'md:py-1 col-span-12 @xl:col-span-6 @7xl:col-span-3';

	return (
		<div className='flex flex-col w-full'>
			<div id='focus-filters' className='grid grid-cols-12 gap-2 @3xl:gap-4 py-2 w-full'>
				{includeTab && sessionData && sessionData.tabs && sessionData.tabs.length > 1 && (
					<div className='flex flex-col w-full col-span-12'>
						<p className='text-neutral-400 text-sm'>Session Tab</p>
						<DropdownMenu>
							<DropdownMenuTrigger asChild className='w-full'>
								<Button className='flex flex-row justify-between px-2 pl-4 overflow-hidden' variant='outline'>
									<p>{`${tab.id ? formatName(tab.title ?? 'New Tab') : 'Any Tab'}`}</p>
									<p className='material-icons'>expand_more</p>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent className={cn('w-56', z_classes.overlay[1])}>
								<DropdownMenuRadioGroup
									value={tab.id}
									onValueChange={id =>
									{
										//Find corresponding tab from id
										const tab = Object.values(sessionData.tabs).find(tab => tab.id === id);
										if (tab)
										{
											setTab({
												id: tab.id,
												title: tab.title
											});
										}
										else
											setTab({
												id: '-1234',
												title: 'Any Tab'
											});
									}}
									className='max-h-[30vh] overflow-y-auto'
								>
									<DropdownMenuRadioItem className='' key={'any_tab_selector'} value={'-1234'}>{`Any Tab`}</DropdownMenuRadioItem>

									{Object.values(sessionData.tabs).map((tab, index) => (
										<DropdownMenuRadioItem className='' key={tab.id} value={tab.id}>{`${formatName(tab.title ?? 'New Tab')} [${
											index + 1
										}]`}</DropdownMenuRadioItem>
									))}
								</DropdownMenuRadioGroup>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>
				)}

				<div id='focus-company-filter' className={'flex flex-col w-full ' + firstRowColspanClasses}>
					<p className='text-left text-neutral-400 w-full'>Related to company</p>
					<div className=''>
						<AdaptiveEnumDropdown
							setValue={setCompany}
							enumValues={companies}
							value={company}
							options={{
								valueName: 'company',
								selectionStyle: 'multiselect',
								stayOpen: true
							}}
							contentProps={{
								className: z_classes.overlay[1]
							}}
						/>
					</div>
				</div>

				<div id='focus-drug-filter' className={'hidden marker:flex flex-col w-full ' + firstRowColspanClasses}>
					<p className='text-left text-neutral-400 w-full'>Related to drug</p>
					<div className=''>
						<AdaptiveEnumDropdown
							setValue={setDrug}
							enumValues={drugs}
							initialSelectionIndices={drug.map(d =>
							{
								const index = drugs.findIndex((drug: { name: string }) => drug.name === d);
								return index === -1 ? 0 : index;
							})}
							options={{
								valueName: 'drug',
								selectionStyle: 'multiselect',
								stayOpen: true
							}}
							contentProps={{
								className: z_classes.overlay[1]
							}}
						/>
					</div>
				</div>

				<div id='focus-type-filter' className={'flex flex-col w-full ' + firstRowColspanClasses}>
					<p className='text text-neutral-400'>Document Type</p>
					<AdaptiveEnumDropdown
						setValue={setDocumentType}
						enumValues={documentTypeOptions}
						valuesForInitialSelection={documentType}
						options={{
							valueName: 'document type',
							selectionStyle: 'multiselect',
							stayOpen: true
						}}
						contentProps={{
							className: z_classes.overlay[1]
						}}
					/>
				</div>

				<div id='focus-date-filter' className={'flex flex-col w-full ' + thirdRowColspanClasses}>
					<p className='text text-neutral-400'>Start Date</p>
					<div className='lg:'>
						<DatePicker date={new Date(startDate)} setDate={setStartDate} />
					</div>
				</div>

				<div className={'flex flex-col w-full ' + thirdRowColspanClasses}>
					<p className='text text-neutral-400'>End Date</p>
					<div className='lg:'>
						<DatePicker date={new Date(endDate)} setDate={setEndDate} />
					</div>
				</div>
			</div>
		</div>
	);
};

const FocusTrigger = memo(() =>
{
	const [open, setOpen] = useState(false);

	const focusSelection = useSelector((state: RootState) => state.focus.focusSelection);
	const setFocusSelection = (value: string) => store.dispatch(setFocusSelectionRedux(value));

	const items = useSelector((state: RootState) => state.focus.items);
	const tab = useSelector((state: RootState) => state.focus.tab);
	const company = useSelector((state: RootState) => state.focus.company);
	const drug = useSelector((state: RootState) => state.focus.drug);
	const documentType = useSelector((state: RootState) => state.focus.documentType);
	const startDate = useSelector((state: RootState) => state.focus.startDate);
	const endDate = useSelector((state: RootState) => state.focus.endDate);

	const popoverRef = useRef<any>(null);

	//Count applied filters
	const appliedCount = useMemo(() =>
	{
		let count = 0;

		if (focusSelection === 'item')
		{
			return items.length;
		}

		if (tab.id !== '') count++;

		if (company.length > 0 && company[0] !== 'Any Company') count++;

		if (drug.length > 0 && drug[0] !== 'Any Drug') count++;

		if (documentType.length > 0) count++;

		if (startDate !== '01/01/2010' && startDate !== '01-01-2010') count++;

		//If end date is not today -- but ignore h/m/s
		//console.log(format(endDate, 'MM/dd/yyyy'), format(new Date(), 'MM/dd/yyyy'))
		if (format(endDate, 'MM/dd/yyyy') !== format(new Date(), 'MM/dd/yyyy')) count++;

		//console.log('Document type', documentType);

		//setAppliedCount(count);
		return count;
	}, [focusSelection, items, tab, company, drug, documentType, startDate, endDate]);

	//If no items are able to be focused, there's no need to display information about context focus.
	const showDocFocus = useSelector((state: RootState) =>
		state.session.sessionData?.tabs?.some(tab => tab.actions?.some(action => action.action_type.includes('doc') || action.action_type.includes('detail')))
	);

	console.log('FocusTrigger render', open);

	return (
		<AdaptivePopover
			open={open}
			onOpenChange={setOpen}
			trigger={
				<div
					className='flex flex-row items-center gap-x-2 px-2 bg-neutral-100 hover:bg-neutral-200 active:bg-neutral-300 rounded-md'
					onClick={() =>
					{
						setOpen(!open);
					}}
				>
					<FilterList />
					{focusSelection.includes('item') ? (
						<p>{appliedCount > 0 ? `${appliedCount} item${appliedCount > 1 ? 's' : ''} focused ` : 'Set Focus'}</p>
					) : (
						<p>{appliedCount > 0 ? `${appliedCount} Filter${appliedCount > 1 ? 's' : ''} Applied` : 'Set Focus'}</p>
					)}
				</div>
			}
			popoverContentProps={{
				className: 'flex flex-col p-4 bg-white min-w-screen sm:min-w-[480px] gap-y-2',
				ref: popoverRef
			}}
			overlayContentProps={{
				className: 'flex flex-col w-screen h-auto bg-white rounded-md border-neutral-200 p-2'
			}}
			dependencies={[focusSelection, items]}
		>
			<Tabs
				value={focusSelection}
				onValueChange={setFocusSelection}
				onClick={e =>
				{
					console.log('Clicked on tabs');
					e.stopPropagation();
				}}
				className={cn('p-0.5! p-0 m-0', z_classes.overlay[1])}
			>
				{showDocFocus && (
					<TabsList className='w-full p-1 text-sm'>
						<button
							className='w-full h-full rounded-md'
							onClick={e =>
							{
								e.stopPropagation();
								setFocusSelection('focus');
							}}
						>
							<p className={cn('flex w-full items-center justify-center', focusSelection === 'focus' ? 'bg-white text-black w-full h-full' : '')}>
								Focus Filters
							</p>
						</button>
						<button
							className='w-full h-full rounded-md'
							onClick={e =>
							{
								e.stopPropagation();
								setFocusSelection('item');
							}}
						>
							<p className={cn('flex w-full items-center justify-center', focusSelection === 'item' ? 'bg-white text-black w-full h-full' : '')}>
								Context Filters
							</p>
						</button>
					</TabsList>
				)}
				<TabsContent value='focus'>
					<div className='flex flex-col'>
						<div className='flex flex-row justify-between'>
							<p className='text-xl text-neutral-900'>Set Focus Filters</p>
							<div className='flex flex-row gap-x-2'>
								<div
									className='flex flex-row items-center gap-x-2 py-1 px-2 rounded-md text-neutral-500 hover:text-neutral-900 cursor-pointer'
									onClick={() =>
									{
										resetFocusFilters();
									}}
								>
									<p>Reset</p>
									<Reset className='text-[24px]' />
								</div>
								<NavIcon
									iconComponent={<Close />}
									className='text-[24px] cursor-pointer'
									onClick={() =>
									{
										setOpen(false);
										store.dispatch(setOverlay(null));
									}}
								/>
							</div>
						</div>
						<p className='text-sm text-neutral-400'>
							When set, AI Answers will only utilize source items that match the provided focus filters when generating responses.
						</p>
					</div>
					<FocusFilters includeTab />
				</TabsContent>
				<TabsContent value='item' className='flex flex-col gap-y-2'>
					<div className='flex flex-col p-2 pb-0'>
						<div className='flex flex-row justify-between'>
							<p className='text-xl text-neutral-900'>Set Context Focus</p>
							<div className='flex flex-row gap-x-2'>
								<div
									className='flex flex-row items-center gap-x-2 py-1 px-2 rounded-md text-neutral-500 hover:text-neutral-900 cursor-pointer'
									onClick={() =>
									{
										store.dispatch(setItems([]));
									}}
								>
									<p>Clear</p>
									<Reset className='text-[24px]' />
								</div>
								<NavIcon
									iconComponent={<Close />}
									className='text-[24px] cursor-pointer'
									onClick={() =>
									{
										setOpen(false);
										store.dispatch(setOverlay(null));
									}}
								/>
							</div>
						</div>
						<p className='text-sm text-neutral-400'>When set, AI Answers will only utilize the specified items when generating responses.</p>
					</div>
					<div className='flex flex-col gap-y-2'>
						{items.length === 0 ? (
							<div className='flex flex-col items-center justify-center text-center p-4 border border-dashed rounded-md text-neutral-400 text-sm'>
								<p>No Items are currently focused. </p>
								<p className=''>To focus a document click “Add to Chat” when viewing a document or reviewing search results.</p>
							</div>
						) : (
							items.map(doc => (
								<div key={doc.id} className='flex flex-row p-4 border border-neutral-200 rounded-md justify-between items-center'>
									<p className='pr-2'>{doc.title}</p>
									<span
										className='text-[24px] cursor-pointer text-neutral-500 hover:text-neutral-900'
										onClick={() =>
											{
											const newDocs = items.filter(d => d.id !== doc.id);
											store.dispatch(setItems(newDocs));
										}}
									>
										<Trash />
									</span>
								</div>
							))
						)}
					</div>
				</TabsContent>
			</Tabs>
		</AdaptivePopover>
	);
});

FocusTrigger.displayName = 'FocusTrigger';

export { FocusFilters, FocusTrigger, resetFocusFilters };
