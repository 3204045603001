const logPerformance = true;

const setMountTime = (time) => {
   globalThis.mountTime = time;
   console.log('[Profiling] Mount time: ' + (globalThis.mountTime) + 'ms');
}

const setStartConnectionTime = (time) => {
   globalThis.startConnectionTime = time;
   console.log('[Profiling] Component mount to connection time: ' + (globalThis.startConnectionTime - globalThis.mountTime) + 'ms');
}

const setConnectionTime = (time) => {
   globalThis.connectionTime = time;
   console.log('[Profiling] Connection time: ' + (globalThis.connectionTime - globalThis.startConnectionTime) + 'ms');
}

const setSessionsFetchTime = (time) => {
   if(globalThis.sessionsFetchTime)
      return;

   globalThis.sessionsFetchTime = time;
   console.log('[Profiling] Sessions fetch time: ' + (globalThis.sessionsFetchTime - globalThis.connectionTime) + 'ms');
}

const setSessionRetrievalTime = (time) => {
   globalThis.sessionRetrievalTime = time;
   console.log('[Profiling] Session retrieval time: ' + (globalThis.sessionRetrievalTime - globalThis.sessionsFetchTime) + 'ms');
}

const setTabRetrievalTime = (time) => {
   if(globalThis.tabRetrievalTime)
      return;

   globalThis.tabRetrievalTime = time;
   console.log('[Profiling] Tab retrieval time: ' + (globalThis.tabRetrievalTime - globalThis.sessionRetrievalTime) + 'ms');
}

const setActualRenderTime = (time) => {
   if(globalThis.actualRenderTime)
      return;

   globalThis.actualRenderTime = time;
   console.log('[Profiling] Actual render time: ' + (globalThis.actualRenderTime - globalThis.tabRetrievalTime) + 'ms');
}

export { logPerformance, setMountTime, setStartConnectionTime, setConnectionTime, setSessionsFetchTime, setSessionRetrievalTime, setTabRetrievalTime, setActualRenderTime };